body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span {
  letter-spacing: 0.3px;
}
p {
  letter-spacing: 0.3px;
}

b {
  color: #062841;
  letter-spacing: 0.3px;
}
/* width */
::-webkit-scrollbar {
  width: 0.3vw;
  height: 1vw;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #cce9ff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(254, 70, 184);
}

.shadow {
  -moz-box-shadow: 0 0 0.4vw rgb(179, 255, 0);
  -webkit-box-shadow: 0 0 0.4vw rgba(26, 116, 74, 1);
  box-shadow: 0 0 0.4vw rgba(26, 116, 74, 1);
}

:root {
  --orange: rgb(241, 189, 66);
  --orange2: rgb(182, 129, 5);
}
